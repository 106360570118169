<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <div class="box-name-page">
        <p class="title">จัดการสิทธิ์</p>
      </div>
      <el-row :gutter="20">
        <el-form label-position="top" class="set-form">
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ฝ่าย">
              <el-select
                id="selectDepartment"
                multiple
                filterable
                default-first-option
                clearable
                v-model="form.department"
                placeholder="เลือกฝ่าย"
                popper-class="custom-select"
                class="w-100"
                @change="selectDepartment"
                :loading="loadingDepartment"
              >
                <template slot="empty" v-if="!loadingDepartment">
                  <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                </template>
                <el-option
                  v-for="item in optionsDepartment"
                  :key="item.id"
                  :label="item.depName"
                  :value="item.id"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.depName }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ส่วน">
              <el-select
                id="selectDivision"
                multiple
                filterable
                default-first-option
                clearable
                v-model="form.division"
                placeholder="เลือกส่วน"
                popper-class="custom-select"
                class="w-100"
                @change="selectDivision"
                :disabled="form.department == '' ? true : false"
              >
                <template slot="empty">
                  <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                </template>
                <el-option-group
                  v-for="group in optionsDivision"
                  :key="group.value"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.option"
                    :key="item.id"
                    :label="item.divName"
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.divName }}
                    </div>
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ตำแหน่ง">
              <el-select
                id="selectPosition"
                multiple
                filterable
                default-first-option
                clearable
                v-model="form.position"
                placeholder="เลือกตำแหน่ง"
                popper-class="custom-select"
                class="w-100"
                @change="selectAllPosition"
                :disabled="form.division == '' ? true : false"
              >
                <template slot="empty">
                  <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                </template>
                <el-option-group
                  v-for="group in optionsPosition"
                  :key="group.value"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.option"
                    :key="item.id"
                    :label="item.posName"
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.posName }}
                    </div>
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="สถานะใน ธสน.">
              <el-select
                id="selectEmpStatus"
                v-model="form.empStatus"
                placeholder="เลือกสถานะ"
                @change="searchAdminAll"
              >
                <el-option
                  v-for="item in optionsStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ค้นหา">
              <el-input
                clearable
                id="searchAdmin"
                placeholder="ค้นหาชื่อ/นามสกุล/อีเมล"
                suffix-icon="el-icon-search"
                v-model="form.searchData"
                class="search"
                @input="searchAdminByKeyboard"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="สิทธิ์การเข้าถึง">
              <el-select
                id="selectRole"
                multiple
                filterable
                default-first-option
                clearable
                v-model="form.roleId"
                placeholder="เลือกสิทธิ์การเข้าถึง"
                popper-class="custom-select"
                class="w-100"
                @change="searchAdminAll"
              >
                <el-option
                  v-for="item in optionsRole"
                  :key="item._id"
                  :label="item.roleName"
                  :value="item._id"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.roleName }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6" v-if="checkPermissionMore(7)">
            <el-form-item label="สถานะใน CFOE">
              <el-select
                id="selectCfoeStatus"
                v-model="form.cfoeStatus"
                placeholder="เลือกสถานะ"
                @change="searchAdminAll"
              >
                <el-option
                  v-for="item in optionsCfoeStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div class="box-card mg-t-5">
      <el-row :gutter="20">
        <el-form label-position="top" class="set-form">
          <el-col
            :span="24"
            :sm="12"
            :md="9"
            v-if="checkPermissionMore(6, 'edit')"
          >
            <p class="font-22">เปลี่ยนแปลงสิทธิ์ผู้ใช้งาน</p>
            <el-row :gutter="10">
              <el-col :span="24" :sm="17" :md="16" :lg="18">
                <el-select
                  filterable
                  id="changeRole"
                  v-model="formChange.changeRole"
                  placeholder="เปลี่ยนแปลงสิทธิ์ไปยัง..."
                >
                  <el-option
                    v-for="item in optionsSelectRole"
                    :key="item._id"
                    :label="item.roleName"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="24" :sm="7" :md="8" :lg="6">
                <el-button
                  type="primary"
                  class="font-16 submit w-100"
                  @click="openDialogChange"
                  :disabled="
                    formChange.changeRole != '' &&
                    multipleSelectionId.length >= 1
                      ? false
                      : true
                  "
                  >เปลี่ยนแปลง</el-button
                >
              </el-col>
            </el-row>
          </el-col>
          <el-col
            :span="24"
            :sm="12"
            :md="9"
            v-if="checkPermissionMore(7, 'edit')"
          >
            <p class="font-22">ตั้งค่าสถานะใน CFOE</p>
            <el-row :gutter="10">
              <el-col :span="24" :sm="17" :md="16" :lg="18">
                <el-select
                  id="changeCfoeStatus"
                  v-model="formChange.cfoeStatus"
                  placeholder="เลือกการตั้งค่าสถานะ"
                >
                  <el-option
                    v-for="item in optionsCfoeStatus1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="24" :sm="7" :md="8" :lg="6">
                <el-button
                  type="primary"
                  class="font-16 submit w-100"
                  @click="openCfoeStatus"
                  :disabled="
                    formChange.cfoeStatus != '' &&
                    multipleSelectionId.length >= 1
                      ? false
                      : true
                  "
                  >ดำเนินการ</el-button
                >
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <p
              v-if="multipleSelectionId.length > 0 && showCountSelect"
              class="font-22 mg-t-3"
            >
              คุณเลือกรายชื่อสิทธิ์ผู้ใช้งาน
              <span v-if="getSelectAll.length != 0">
                <span class="color-light-blue">{{
                  multipleSelectionId.length
                }}</span>
                ราย จากทั้งหมด {{ getSelectAll.totalPage }} ราย
              </span>
              <span v-else>
                <span class="color-light-blue">{{
                  multipleSelectionId.length
                }}</span>
                ราย จากทั้งหมด {{ dataRes.totalPage }} ราย
              </span>
            </p>
            <!-- <p class="text-right color-9c font-18">
              <span>Last Update: {{ timeSync }}</span>
            </p> -->
          </el-col>
        </el-form>

        <el-col :span="24" class="mg-t-3">
          <el-row>
            <div>
              <el-col :span="24">
                <TableAdmin
                  :data="dataRes.data"
                  @openSetting="editSetting"
                  @exportId="getManageId"
                  @exportBy="exportBy"
                  :totalNow="totalNow"
                  :statusCheck="statusOutNow"
                  :loading="loading"
                  :lengthMulti="multipleSelectionId.length"
                  :checkSelect="multipleSelectionId"
                />
                <div class="text-center mg-t-5" v-if="totalNow > 0">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="changePage"
                    :current-page.sync="pageNow"
                    :page-sizes="[15, 30, 50, 100, 500]"
                    :page-size="15"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNow"
                  >
                  </el-pagination>
                  <!-- <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="totalNow"
                    :page-size="limit"
                    :current-page="pageNow"
                    @current-change="changePage"
                  ></el-pagination> -->
                </div>
              </el-col>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      v-if="dialogCfoeStatus"
      :visible.sync="dialogCfoeStatus"
      width="35%"
      class="dialog-custom admin"
    >
      <div slot="title">
        <p class="text-center color-00 font-34 mg-0">
          คุณยืนยันการเปลี่ยนแปลงตั้งค่าสถานะใน CFOE
        </p>
      </div>
      <p class="text-center color-00 font-20 mg-0">
        คุณเลือกรายชื่อสิทธิ์ที่ต้องการเปลี่ยนแปลงทั้งหมด
        <span class="color-light-blue">
          {{ multipleSelectionId.length }} ราย
        </span>
      </p>
      <!-- ไม่ใช้แล้วเพราะพี่แป้งเอาออก -->
      <!-- <el-row :gutter="10" class="mg-t-4 is-flex ai-center">
        <el-col :sm="10">
          <el-input v-model="statusBefore" disabled> </el-input>
        </el-col>
        <el-col :sm="4" class="text-center">
          <i class="fas fa-long-arrow-alt-right font-36 color-main"></i
        ></el-col>
        <el-col :sm="10">
          <el-select
            v-model="formChange.cfoeStatus"
            placeholder="เปลี่ยนแปลงสิทธิ์ไปยัง..."
            class="w-100"
          >
            <el-option
              v-for="item in optionsCfoeStatus1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleChangeStatusCfoe"
          class="font-16 btn-cancle min-100"
          @click="dialogCfoeStatus = false"
        >
          ยกเลิก</el-button
        >
        <el-button
          id="saveChangeStatusCfoe"
          type="primary"
          class="font-16 min-100"
          @click="saveChangeStatusCfoe"
          :loading="loadingSave"
          >บันทึก</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      class="dialog-custom admin"
      title="แก้ไขสิทธิ์การเข้าถึง"
      v-if="dialogEdit"
      :visible.sync="dialogEdit"
      :before-close="handleClose"
      width="70%"
      top="10vh"
      center
    >
      <div v-loading="loadingEdit">
        <div v-if="!loadingEdit">
          <p
            class="text-center font-20"
            v-if="
              arrPermission.role == null &&
              arrPermission.permissionCustom.length == 0
            "
          >
            กรุณาเลือกสิทธิ์ผู้ใช้งาน
          </p>
          <ManagePermission
            v-else
            :type="permissionCustom == true ? 'editUserByCustom' : `editUser`"
            :dataRes="arrPermission"
            @saveData="saveData"
            @closeModal="closeModal"
            class="table-manage"
          />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogChange"
      width="35%"
      class="dialog-custom admin"
    >
      <div slot="title">
        <p class="text-center color-00 font-34 mg-0">
          คุณยืนยันการเปลี่ยนแปลงสิทธิ์ผู้ใช้งาน
        </p>
      </div>
      <p class="text-center color-00 font-20 mg-0">
        คุณเลือกรายชื่อสิทธิ์ที่ต้องการเปลี่ยนแปลงทั้งหมด

        <span v-if="getSelectAll.length != 0" class="color-light-blue">
          {{ getSelectAll.totalPage }} ราย
        </span>
        <span v-else class="color-light-blue">
          {{ multipleSelectionId.length }} ราย
        </span>
      </p>
      <el-row v-for="(dataChange, index) in moveRole" :key="index">
        <el-col :span="24">
          <p class="color-00 font-20 mg-0 mg-t-4">
            คุณเลือกรายชื่อสิทธิ์ที่ต้องการเปลี่ยนแปลงทั้งหมด
            <span class="color-light-blue">
              {{ dataChange.empRole.length }} ราย
            </span>
          </p>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="10" class="mg-t-6 is-flex ai-center">
            <el-col :sm="10">
              <el-input v-model="dataChange.roleBefore" disabled> </el-input>
            </el-col>
            <el-col :sm="4" class="text-center">
              <i class="fas fa-long-arrow-alt-right font-36 color-main"></i
            ></el-col>
            <el-col :sm="10">
              <el-select
                filterable
                :id="`changeRole` + index"
                v-model="dataChange.roleId"
                placeholder="เปลี่ยนแปลงสิทธิ์ไปยัง..."
                @change="changeInModal(index)"
              >
                <el-option
                  v-for="item in optionsSelectRole"
                  :key="item._id"
                  :label="item.roleName"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleChangeRole"
          class="font-16 btn-cancle min-100"
          @click="dialogChange = false"
        >
          ยกเลิก</el-button
        >
        <el-button
          id="saveChangeRole"
          type="primary"
          class="font-16 min-100"
          @click="saveChangeRole"
          :loading="loadingSave"
          >บันทึก</el-button
        >
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import TableAdmin from "@/components/admin/TableAdmin";
import ManagePermission from "@/components/admin/ManagePermission";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    TableAdmin,
    ManagePermission,
  },
  data() {
    return {
      isIndeterminate: false,
      loadingSave: false,
      statusBefore: "",
      roleBefore: "",
      loadingEdit: false,
      form: {
        department: [],
        division: [],
        position: [],
        roleId: [],
        empStatus: "Active",
        searchData: "",
        cfoeStatus: "",
      },
      formChange: {
        changeRole: "",
        cfoeStatus: "",
      },
      optionsDepartment: [],
      optionsDivision: [],
      optionsPosition: [],
      optionsSelectRole: [],
      optionsStatus: [
        {
          value: "",
          label: "ทั้งหมด",
        },
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "InActive",
          label: "InActive",
        },
      ],
      optionsCfoeStatus: [
        {
          value: "",
          label: "ทั้งหมด",
        },
        {
          value: 1,
          label: "ใช้งาน",
        },
        {
          value: 2,
          label: "ละเว้น",
        },
      ],
      optionsCfoeStatus1: [
        {
          value: 1,
          label: "ใช้งาน",
        },
        {
          value: 2,
          label: "ละเว้น",
        },
        {
          value: 3,
          label: "ลบรายชื่อ",
        },
      ],
      totalNow: null,
      limit: 15,
      pageNow: 1,
      dataRes: [],
      multipleSelectionId: [],
      loading: false,
      dialogEdit: false,
      arrPermission: [],
      dialogCfoeStatus: false,
      statusOutNow: "",
      dialogChange: false,
      getSelectAll: [],
      showCountSelect: false,
      moveRole: [],
      debounce: null,
      optionsRole: [],
      timeSync: "",
      permissionCustom: false,
      loadingDepartment: true,
      constSelect2: [],
      constSelect3: [],
      loadingData: true,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      permissionNow: (state) => state.permissionNow,
    }),
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "manage-admin/manage-role");
    this.getDepartment();
    this.getStatusRole();
    this.getRole();
    this.getAdmin();
  },
  methods: {
    getRole() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`role`)
        .then((res) => {
          if (res.data.success) {
            this.timeSync = res.data.syncLatest;
            this.optionsRole = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getStatusRole() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`role/show`)
        .then((res) => {
          if (res.data.success) {
            this.optionsSelectRole = res.data.obj.data;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    checkPermissionMore(valId, namePage) {
      let ifPermission = null;
      if (namePage == "edit") {
        ifPermission = this.permissionNow.some(
          (e) => e.pemNum == valId && e.status == 2
        );
      } else {
        ifPermission = this.permissionNow.some(
          (e) => e.pemNum == valId && e.status != 3
        );
      }
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    getManageId(val, typePage) {
      if (typePage == "thisPage") {
        this.multipleSelectionId = val;
        this.getSelectAll = [];
      } else {
        let checkIndex = this.multipleSelectionId.findIndex((e) =>
          typeof e.id != "undefined" ? e.id == val.empId : e.empId == val.empId
        );
        if (checkIndex == -1) {
          this.multipleSelectionId.push(val);
        } else {
          this.multipleSelectionId.splice(checkIndex, 1);
        }
      }
      setTimeout(() => {
        this.showCountSelect = true;
      }, 200);
    },
    exportBy(val) {
      if (val == "all") {
        this.showCountSelect = false;

        let obj = {
          key: this.form.searchData,
          depId: this.form.department,
          divId: this.form.division,
          posId: this.form.position,
          empStatus: this.form.empStatus,
          cfoeStatus: this.form.cfoeStatus,
        };
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        HTTP.post(`emp-admin/select-all`, obj)
          .then((res) => {
            if (res.data.success) {
              this.statusOutNow = "all";
              this.getSelectAll = res.data.obj;
              this.multipleSelectionId = res.data.obj.data;
            }
          })
          .catch((e) => {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
          })
          .finally(() => {
            this.showCountSelect = true;
          });
      } else {
        this.statusOutNow = "";
      }
    },
    getAdmin() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        page: this.pageNow,
        limit: this.limit,
        key: this.form.searchData,
        depId: this.form.department,
        divId: this.form.division,
        posId: this.form.position,
        roleId: this.form.roleId.filter((e) => e != 0),
        empStatus: this.form.empStatus,
        cfoeStatus: this.form.cfoeStatus,
        custom: this.form.roleId.some((e) => e == 0) ? 1 : 0,
      };
      HTTP.post(`emp-admin`, obj)
        .then((res) => {
          if (res.data.success) {
            this.timeSync = res.data.obj.syncLatest;
            this.dataRes = res.data.obj;
            this.totalNow = res.data.obj.totalPage;
            let setArr = [];
            this.dataRes.data.forEach((e) => {
              setArr.push({
                ...e,
                checkbox: false,
              });
            });
            this.dataRes.data = setArr;
            this.loading = false;
            this.loadingData = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
        })
        .catch((e) => {
          this.loadingData = false;
          this.loading = false;
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 422) {
              this.dataRes = [];
              this.totalNow = 0;
            } else if (e.response.data.status == 502) {
              console.log("Bad Gateway");
            } else {
              this.alertCatchError(e);
            }
          }
        })
          .finally(() => {
          this.loadingData = false;
          this.loading = false;
          });
    },
    searchAdminAll() {
      this.pageNow = 1;
      this.loading = true;
      this.getAdmin();
    },
    searchAdminByKeyboard() {
      this.pageNow = 1;
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getAdmin();
      }, 600);
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.pageNow = page;
      this.loading = true;
      setTimeout(() => {
        this.getAdmin();
      }, 100);
    },
    selectDepartment() {
      this.optionsDivision = [
        {
          option: [{ id: 999, divName: "ทั้งหมด" }],
        },
      ];
      this.form.department.forEach((dep) => {
        const setArr = this.optionsDepartment.filter((f) => f.id == dep);
        const forOption = [];
        setArr.forEach((arr) => {
          if (arr.division.length > 0) {
            arr.division.forEach((div) => {
              forOption.push(div);
            });
          }
        });
        let obj = {
          label: setArr[0].depName,
          option: forOption,
        };
        this.optionsDivision.push(obj);
      });
      this.selectDivision();
      this.selectAllPosition();
      if (this.form.department == 0) {
        this.form.division = [];
        this.form.position = [];
      }
      this.searchAdminAll();
    },

    selectDivision(val) {
      if (typeof val == "undefined") {
        let findDiv999 = this.form.division.find((e) => e == 999);
        if (typeof findDiv999 != "undefined") {
          let groupDiv = [];
          if (this.form.department.length > 0) {
            this.optionsDivision.forEach((f) => {
              f.option.forEach((option) => {
                groupDiv.push(option.id);
              });
            });
          }
          this.form.division = groupDiv;
          this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
        } else {
          if (this.form.division.length > 0) {
            let groupDiv = [];
            this.form.division.forEach((row) => {
              this.optionsDivision.forEach((f) => {
                f.option.forEach((option) => {
                  if (option.id == row) {
                    groupDiv.push(option.id);
                  }
                });
              });
            });
            this.form.division = groupDiv;
          }
        }
      } else {
        if (val.length == 1) {
          if (val[0] === 999 && val.length < this.constSelect2.length) {
            // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
            this.form.division.splice(0, 1);
            this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
          }
        }
        this.selectAllDiv(val);
      }
      this.optionsPosition = [
        {
          option: [{ id: 999, posName: "ทั้งหมด" }],
        },
      ];
      let setArr = [];
      this.form.division.forEach((e) => {
        this.optionsDivision.forEach((f) => {
          f.option.forEach((option) => {
            if (option.id == e && option.id != 999) {
              setArr.push(option);
            }
          });
        });
      });
      setArr.forEach((arr) => {
        let forOption = [];
        if (arr.position.length > 0) {
          arr.position.forEach((div) => {
            forOption.push(div);
          });
        }
        let obj = {
          label: arr.divName,
          option: forOption,
        };
        this.optionsPosition.push(obj);
      });
      if (this.form.division.length == 0) {
        this.form.position = [];
      }
      this.selectAllPosition();
      this.searchAdminAll();
    },
    selectAllDiv(val) {
      let countDiv = [];
      if (typeof val != "undefined") {
        if (val[val.length - 1] == 999) {
          // เลือกทั้งหมด
          this.form.division = [];
          let groupDiv = [];
          this.optionsDivision.forEach((f) => {
            f.option.forEach((option) => {
              if (option != 999) {
                groupDiv.push(option.id);
              }
            });
          });
          this.form.division = groupDiv;
          this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
        } else {
          this.optionsDivision.forEach((row) => {
            row.option.forEach((option) => {
              countDiv.push(option);
            });
          });
          if (val[0] === 999 && val.length < countDiv.length) {
            // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
            this.form.division.splice(0, 1);
            this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
          } else if (this.constSelect2[0] === 999 && val[0] !== 999) {
            // เอาออกทั้งหมด
            this.form.division = [];
            this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
          } else {
            // Tik ลูกทั้งหมดแล้วให้ เลือกทั้งหมด
            let fn = this.constSelect2.findIndex((a) => a === 999);
            if (val.length == countDiv.length - 1 && fn === -1) {
              this.form.division = [];
              this.optionsDivision.forEach((f) => {
                f.option.forEach((option) => {
                  this.form.division.push(option.id);
                });
              });
              this.constSelect2 = JSON.parse(
                JSON.stringify(this.form.division)
              );
            }
          }
        }
      }
    },
    selectAllPosition(val) {
      let countDiv = [];
      if (this.form.position.length > 0) {
        if (typeof val != "undefined") {
          if (val.length == 1) {
            if (val[0] === 999 && val.length < this.constSelect3.length) {
              // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
              this.form.position.splice(0, 1);
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.form.position)
              );
            }
          }
          if (val[val.length - 1] == 999) {
            // เลือกทั้งหมด
            this.form.position = [];
            this.optionsPosition.forEach((f) => {
              f.option.forEach((option) => {
                if (option != 999) {
                  this.form.position.push(option.id);
                }
              });
            });
            this.constSelect3 = JSON.parse(JSON.stringify(this.form.position));
          } else {
            this.optionsPosition.forEach((row) => {
              row.option.forEach((option) => {
                countDiv.push(option);
              });
            });
            if (val[0] === 999 && val.length < countDiv.length) {
              // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
              this.form.position.splice(0, 1);
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.form.position)
              );
            } else if (this.constSelect3[0] === 999 && val[0] !== 999) {
              // เอาออกทั้งหมด
              this.form.position = [];
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.form.position)
              );
            } else {
              // Tik ลูกทั้งหมดแล้วให้ เลือกทั้งหมด
              let fn = this.constSelect3.findIndex((a) => a === 999);
              if (val.length == countDiv.length - 1 && fn === -1) {
                this.form.position = [];
                this.optionsPosition.forEach((f) => {
                  f.option.forEach((option) => {
                    this.form.position.push(option.id);
                  });
                });
                this.constSelect3 = JSON.parse(
                  JSON.stringify(this.form.position)
                );
              }
            }
          }
        } else {
          let findPosition999 = this.form.position.find((e) => e == 999);
          if (typeof findPosition999 != "undefined") {
            let groupDiv = [];
            this.optionsPosition.forEach((f) => {
              f.option.forEach((option) => {
                if (option != 999) {
                  groupDiv.push(option.id);
                }
              });
            });
            this.form.position = groupDiv;
          } else {
            if (this.form.position.length > 0) {
              let groupDiv = [];

              this.form.division.forEach((row) => {
                this.form.position.forEach((pos) => {
                  this.optionsPosition.forEach((f) => {
                    f.option.forEach((option) => {
                      if (
                        option.divId == row &&
                        option.id != 999 &&
                        option.id == pos
                      ) {
                        groupDiv.push(option.id);
                      }
                    });
                  });
                });
              });
              this.form.position = groupDiv;
            }
          }
        }
      }
      this.searchAdminAll();
    },
    getDepartment() {
      HTTP.get(`department`)
        .then((res) => {
          if (res.data.success) {
            this.loadingDepartment = false;
            this.optionsDepartment = res.data.obj;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
          }
        });
    },
    getPosition() {
      HTTP.get(`position`)
        .then((res) => {
          if (res.data.success) {
            this.optionsPosition = res.data.obj;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
          }
        });
    },

    editSetting(userId) {
      this.dialogEdit = true;
      this.loadingEdit = true;
      this.getPermissionById(userId);
    },
    getPermissionById(id) {
      HTTP.get(`emp-admin/custom-permission/${id}`)
        .then((res) => {
          if (res.data.success) {
            this.dataResById = res.data.obj;
            this.permissionCustom = false;

            if (
              this.dataResById.permissionCustom.length == 0 &&
              this.dataResById.role == null
            ) {
              this.loadingEdit = false;
            } else if (this.dataResById.permissionCustom.length > 0) {
              this.permissionCustom = true;
              this.dataResById.permissionCustom.forEach((e) => {
                let view = 0;
                let edit = 0;
                let hide = 0;
                if (e.status == 1) {
                  view = 1;
                } else if (e.status == 2) {
                  view = 1;
                  edit = 1;
                } else if (e.status == 3) {
                  hide = 1;
                }
                e.value = {
                  view: view,
                  edit: edit,
                  hide: hide,
                };
              });
            } else {
              this.dataResById.role.permission.forEach((e) => {
                let view = 0;
                let edit = 0;
                let hide = 0;
                if (e.status == 1) {
                  view = 1;
                } else if (e.status == 2) {
                  view = 1;
                  edit = 1;
                } else if (e.status == 3) {
                  hide = 1;
                }
                e.value = {
                  view: view,
                  edit: edit,
                  hide: hide,
                };
              });
            }
            let changeJson = JSON.stringify(this.dataResById);
            this.arrPermission = JSON.parse(changeJson);
            this.loadingEdit = false;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
          }
        });
    },
    saveData(arr) {
      if (typeof arr != "undefined") {
        const fetchPermission = [];
        let dataFilter = [];
        if (arr.permissionCustom.length > 0) {
          dataFilter = arr.permissionCustom;
        } else {
          dataFilter = arr.role.permission;
        }
        let obj = {
          empId: arr.id,
          permission: fetchPermission,
        };
        dataFilter.forEach((e) => {
          fetchPermission.push({
            id: e.id,
            status: e.status,
          });
        });
        let hasSelect = obj.permission.find(
          (checkObj) => checkObj.status == 0 || checkObj.status == false
        );
        if (typeof hasSelect == "undefined") {
          this.dialogEdit = false;

          HTTP.post(`emp-admin/custom-permission`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "แก้ไขสิทธิ์ผู้ใช้งานสำเร็จ!",
                  type: "success",
                  duration: 4000,
                });
                this.getAdmin();
              }
            })
            .catch((e) => {
              if (typeof e.response != "undefined") {
                if (e.response.status != 502) {
                  if (e.response.data.status == 401) {
                    this.checkAuth();
                  } else if (e.response.data.status == 502) {
                    console.log("Bad GetWay");
                  } else {
                    this.alertCatchError(e);
                  }
                }
              }
            });
        } else {
          this.$message({
            message: "กรุณาเลือกสิทธิ์การเข้าถึงของฟังก์ชัน",
            type: "error",
            duration: 4000,
          });
        }
      }
    },
    closeModal() {
      this.dialogEdit = false;
    },
    openDialogChange() {
      this.moveRole = [];
      const result = [];
      if (this.statusOutNow == "all") {
        this.getSelectAll.data.forEach((multi) => {
          if (result.indexOf(multi.role) == -1) {
            this.moveRole.push({
              roleBefore: multi.role != null ? multi.role : "-",
              empRole: this.multipleSelectionId
                .filter((row) => row.role == multi.role)
                .map((e) => ({
                  empId: e.empId,
                  roleId: this.formChange.changeRole,
                })),
              roleId: this.formChange.changeRole,
            });
            result.push(multi.role);
          }
        });
      } else {
        this.multipleSelectionId.forEach((multi) => {
          if (result.indexOf(multi.role) == -1) {
            this.moveRole.push({
              roleBefore: multi.role != null ? multi.role : "-",
              empRole: this.multipleSelectionId
                .filter((row) => row.role == multi.role)
                .map((e) => ({
                  empId: e.empId,
                  roleId: this.formChange.changeRole,
                })),
              roleId: this.formChange.changeRole,
            });
            result.push(multi.role);
          }
        });
      }
      this.dialogChange = true;
    },
    openCfoeStatus() {
      const result = [];
      if (this.statusOutNow == "all") {
        this.getSelectAll.data.forEach((e) => {
          let status = "";
          if (e.status == 1) {
            status = "ใช้งาน";
          } else if (e.status == 2) {
            status = "ละเว้น";
          } else if (e.status == 3) {
            status = "ลบรายชื่อ";
          }
          if (result.indexOf(status) == -1 && e.status != null) {
            result.push(status);
          }
        });
      } else {
        this.multipleSelectionId.forEach((e) => {
          let status = "";
          if (e.status == 1) {
            status = "ใช้งาน";
          } else if (e.status == 2) {
            status = "ละเว้น";
          } else if (e.status == 3) {
            status = "ลบรายชื่อ";
          }
          if (result.indexOf(status) == -1 && e.status != null) {
            result.push(status);
          }
        });
      }
      this.statusBefore = result.join(", ");
      if (result.join(", ") == "") {
        this.statusBefore = "-";
      }
      this.dialogCfoeStatus = true;
    },
    async saveChangeRole() {
      this.loadingSave = true;
      let obj = {
        empRole: [],
      };
      this.moveRole.forEach((moveInfo) => {
        moveInfo.empRole.forEach((move) => {
          obj.empRole.push(move);
        });
      });
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`emp-admin/move-role`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dialogChange = false;
            this.statusOutNow = "";
            this.formChange.changeRole = "";
            this.multipleSelectionId = [];
            this.loading = true;
            this.pageNow = 1;
            this.getAdmin();
            this.$message({
              message: "เปลี่ยนแปลงสิทธิ์ผู้ใช้งานสำเร็จ!",
              type: "success",
              duration: 4000,
            });
            let debounce = null;
            clearTimeout(debounce);
            debounce = setTimeout(() => {
              this.loadingSave = false;
            }, 600);
          }
          return res;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              success: false,
            },
          };
        });
      return res;
    },
    saveChangeStatusCfoe() {
      this.loadingSave = true;
      let obj = {
        cfoeStatus: this.formChange.cfoeStatus,
        employee: [],
      };
      this.multipleSelectionId.forEach((e) => {
        obj.employee.push(e.empId);
      });

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`emp-admin/change-status`, obj)
        .then((res) => {
          if (res.data.success) {
            this.statusOutNow = "";
            this.formChange.cfoeStatus = "";
            this.multipleSelectionId = [];
            this.loading = true;
            this.dialogCfoeStatus = false;
            this.pageNow = 1;
            this.getAdmin();
            if (obj.cfoeStatus == 3) {
              this.$message({
                message: "ลบรายชื่อสำเร็จ!",
                type: "success",
                duration: 4000,
              });
            } else {
              this.$message({
                message: "เปลี่ยนแปลงสถานะใน CFOE สำเร็จ!",
                type: "success",
                duration: 4000,
              });
            }
            let debounce = null;
            clearTimeout(debounce);
            debounce = setTimeout(() => {
              this.loadingSave = false;
            }, 600);
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    handleClose() {
      this.arrPermission = {};
      this.dialogEdit = false;
    },
    changeInModal(index) {
      let dataEditChange = this.moveRole[index];
      dataEditChange.empRole.forEach((emp) => {
        emp.roleId = dataEditChange.roleId;
      });
    },
  },
};
</script>
